import React from 'react';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { createLoadingSelector } from '../../reducers/api';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Avatar, Box, Divider, Grid, Paper, Typography, TableBody, TableRow, Dialog, DialogContent, IconButton, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import { getLatestAssessment, getPatientRecord } from '../../actions/assessmentInfo';
import { become } from '../../actions/session';
import { getPatientList, getPatientRecordAssessment } from '../../apis';
import { getAgefromBirthDate } from '../../helpers/date';
import Layout from '../../components/v2/Layout';
import LoaderView from '../../components/LoaderView';
import SkeletonLoader from '../../components/SkeletonLoader';
import ResultEvalution from '../../components/ResultEvalution';
import Table from '../../components/Table';
import Cell from '../../components/Table/cell';
import SummaryMonthlyEmori from '../../components/SummaryMonthlyEmori';
import { getPartnerId } from '../../helpers/location';

const menus = [
  {
    'name': 'ประวัติ',
    'icon': 'history',
    'path': () => navigate('/history?tab=1'),
  },
  {
    'name': 'ใบรับรองแพทย์',
    'icon': 'medical_certificate',
    'path': () => navigate('/certificate'),
  },
  {
    'name': 'คูปองของฉัน',
    'icon': 'coupon',
    'path': () => navigate('/promotion'),
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    '& > *': {
      margin: theme.spacing(0.25),
      height: theme.spacing(13),
    },
    borderRadius: '12px',
  },
  divider: {
    height: '93%',
    width: '1px',
    marginTop: '25%',
  },
  large: {
    width: theme.spacing(9.5),
    height: theme.spacing(9.5),
    marginLeft: '22px',
    marginTop: '10px',
  },
  grid: {
    flexGrow: '0.3',
  },
  button: {
    marginTop: '10px',
    marginRight: '2px',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(11.5),
      height: theme.spacing(2.75),
    },
    borderRadius: '12px',
  },
  menu: {
    marginTop: '9px',
    width: theme.spacing(4.7),
    height: theme.spacing(4.7),
  },
  menuText: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3px',
  }
}))

export default ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, changed } = useSelector(state => state.session);
  const isLoading = useSelector(createLoadingSelector(['assessment/patient/latest/get', 'assessment/patient/get', 'become/user']));
  const { dashboard, patientRecord } = useSelector(state => state.assessmentInfo);
  const [ patientDateList, setPatientDateList ] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [diseaseRisk, setDiseaseRisk] = React.useState();

  React.useEffect(() => {
    fetchData();
  }, [selectedValue]);

  const fetchData = async () => {
    await dispatch(getPatientRecord());
    const dateList = await getPatientList();
    const disease = await getPatientRecordAssessment(dateList[0].id, !selectedValue ? dateList[0].assessment_datetimes[0] : selectedValue)
    setDiseaseRisk(disease)
    setPatientDateList(dateList);
    if (changed) {
      await dispatch(become());
    }
  }

  if (!user || isLoading) {
    return false;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  let age;
  if (patientRecord.birthday) {
    age = getAgefromBirthDate(patientRecord.birthday);
  }

  return (
    <Layout bg='bg3' title='โปรไฟล์'>
      <Dialog aria-labelledby="simple-dialog-title" onClose={handleClose} open={open}>
        <DialogTitle id="simple-dialog-title">เลือกวันที่ทำแบบประเมิน</DialogTitle>
        <List>
          {patientDateList[0]?.assessment_datetimes.map((date, index) => (
            <ListItem button onClick={() => handleListItemClick(date)} key={date}>
              <ListItemText primary={new Date(date).toLocaleDateString('en-GB')} />
            </ListItem>
          ))}
        </List>
      </Dialog>

      <Typography style={{fontSize: '20px', color: 'white', paddingBottom: '11px', fontWeight: 'bold'}}>{user.fullname}</Typography>
      <div className={classes.root} onClick={() => navigate('/partners/editProfile', { state: { patientRecordData: patientRecord } })}> 
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Avatar className={classes.large} src={user.avatar}></Avatar>
          </Grid>
          <Grid item xs className={classes.grid}>
            <Divider orientation='vertical' className={classes.divider} />
          </Grid>
          <Grid item xs={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <Cell style={{ display: 'flex', alignItems: 'flex-start'}}>
                    <img src={require(`../../assets/images/profile.svg`)} alt='profile_img' />
                  </Cell>
                  <Cell>
                    <Typography variant="subtitle2" component="span" color="textPrimary">อายุ</Typography>
                  </Cell>
                  <Cell>
                    <Typography variant="subtitle2" component="span" color="textPrimary"><strong>{age}</strong></Typography>
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell style={{ display: 'flex', alignItems: 'flex-start'}}>
                  <img src={require(`../../assets/images/sex.svg`)} alt='sex_img'/>
                  </Cell>
                  <Cell>
                    <Typography variant="subtitle2" component="span" color="textPrimary">เพศ</Typography>
                  </Cell>
                  <Cell>
                    <Typography variant="subtitle2" component='span' color="textPrimary"><strong>{patientRecord.gender}</strong></Typography>
                  </Cell>
                </TableRow>
                <TableRow>
                  <Cell style={{ display: 'flex', alignItems: 'flex-start'}}>
                    <img src={require(`../../assets/images/blood.svg`)} alt='blood_img' />
                  </Cell>
                  <Cell>
                    <Typography variant="subtitle2" component="span" color="textPrimary">กรุ๊ปเลือด</Typography>
                  </Cell>
                  <Cell>
                    <Typography variant="subtitle2" component="span" color="textPrimary"><strong>{patientRecord.blood_group}</strong></Typography>
                  </Cell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <ChevronRight />
      </div>
      <Grid container spacing={1}>
        {
          menus.map((menu, index) => {
            return (
              <Grid item xs={4} key={index}>
                <div className={classes.button} onClick={() => menu.path()}>
                  <img className={classes.menu} src={require(`../../assets/images/${menu.icon}.svg`)} alt={`${menu.icon}`} />
                  <Typography gutterBottom color='textPrimary' className={classes.menuText}>{menu.name}</Typography>
                </div>
              </Grid>
            )
          })
        }
      </Grid>
      <Box pt={2} style={{ display: 'flex', alignItems: 'flex-start'}}>
        <Typography gutterBottom style={{color:'white', fontSize: '15px'}}>EMORI สมุดบันทึกอารมณ์ของฉัน</Typography>
      </Box>
      {
        isLoading ? (
          <SkeletonLoader />
        ) : (
          <SummaryMonthlyEmori />
        )
      }
      <Box pt={2} style={{ display: 'flex', alignItems: 'flex-start'}}>
        <Typography gutterBottom style={{color:'white', fontSize: '15px'}}>
          ประเมินเมื่อ {new Date(!selectedValue ? patientDateList[0]?.assessment_datetimes[0] : selectedValue).toLocaleDateString('en-GB')}
        </Typography>
        <img style={{paddingLeft: '8px'}} src={require(`../../assets/images/calendar.svg`)} onClick={handleClickOpen}/>
      </Box>
      {
        isLoading ? (
          <SkeletonLoader />
        ) : (
          <ResultEvalution diseaseRiskList={diseaseRisk?.disease_risks}/>
        )
      }
    </Layout>
  )
}